import styled from '@emotion/styled';
import { getStyleSizes } from '@styles/styles.utils';
import { mq_min } from '@styles/breakpoints';
import { HeroBannerContainerStyledProps } from '@cms-components/hero-banner/hero-banner.interface';
import { GridColProp } from '@cms-components/hero-banner/components/banner-standard/banner-standard.interface';
import { VariantProp } from './hero-banner.interface';
import { noTo_font } from '@src/styles/typography';
import { Splide } from '@splidejs/react-splide';
import {
  color_black,
  color_dark_grey,
  color_text_grey,
  color_darkest_grey,
  color_light_grey,
  color_white,
  color_miami,
  color_blue,
} from '@styles/colors';

export const variants = {
  light: {
    headlineColor: color_darkest_grey,
    detailColor: color_dark_grey,
    iconColor: color_black,
  },
  mid: {
    headlineColor: color_darkest_grey,
    detailColor: color_dark_grey,
    iconColor: color_black,
  },
  dark: {
    headlineColor: color_white,
    detailColor: color_text_grey,
    iconColor: color_white,
  },
};

export const HeroBannerContainer = styled.div`
  overflow: hidden;
`;
export const HeroBannerButtonsWrapper = styled.div`
  margin: ${getStyleSizes([40, 0])};

  & > *:not(:last-child) {
    margin-bottom: ${getStyleSizes(22)};
  }
`;

export const HeroBannerHeadline = styled.h1`
  line-height: 1;
  font-size: ${getStyleSizes(34)};
  font-weight: normal;

  ${mq_min('lg')} {
    font-size: ${getStyleSizes(50)};
  }
`;

export const HeroBannerEyebrow = styled.div<GridColProp>`
  color: ${color_blue};
  font-weight: 600;
  font-size: ${getStyleSizes(16)};
  line-height: 0.75;
  margin-bottom: ${getStyleSizes(4)};

  ${mq_min('lg')} {
    font-size: ${getStyleSizes(18)};
    margin-bottom: ${getStyleSizes(11)};
  }
`;

export const HeroBannerCopy = styled.div<GridColProp>`
  ul,
  ol {
    display: flex;
    flex-direction: row;
    text-align: initial;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-left: ${getStyleSizes(16)};
  }

  ul,
  ol {
    li {
      display: flex;
      flex-basis: 100%;
      width: 100%;
      align-items: flex-start;
      justify-content: flex-start;
      &:before {
        content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath fill='${encodeURIComponent(
          color_miami.base
        )}' d='M3 12L6 9L10 13L19 4L22 7L10 19L3 12Z'/%3e%3c/svg%3e");
        flex: 0 0 ${getStyleSizes(20)};
        margin-right: ${getStyleSizes(5)};
        margin-top: ${getStyleSizes(2)};
      }
      ${mq_min('lg')} {
        flex-basis: 48%;
        width: 48%;
      }
    }
  }
`;

export const HeroBannerLogo = styled.div`
  width: ${getStyleSizes(200)};
  height: ${getStyleSizes(35)};
  margin-bottom: ${getStyleSizes(25)};

  ${mq_min('lg')} {
    margin-bottom: ${getStyleSizes(32)};
  }
`;

export const HeroBannerLogoImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: left center;
`;

export const HeroBannerPicture = styled.picture`
  display: block;
`;

export const HeroBannerImg = styled.img`
  display: block;
  height: auto;
  width: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 4px;
`;

export const HeroLinkStyled = styled('a')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${getStyleSizes(32.5)};
  outline: none;
  text-decoration: none;
  height: ${getStyleSizes(44)};
  border-radius: ${getStyleSizes(4)};
  background: ${color_miami.base};
  width: ${getStyleSizes(222)};

  &:hover{
    background: ${color_miami.dark};
  }

  ${mq_min('lg')} {
    margin-top: ${getStyleSizes(33.5)};
  }
`
export const HeroLinkSpanStyled = styled('span')<VariantProp>`
  color: ${({ variant }) => variants[variant]?.iconColor};
  font-size: ${getStyleSizes(16)};
  font-weight: 400;
  line-height: ${getStyleSizes(16)};
  margin-right: ${getStyleSizes(16)};
  letter-spacing: 0px;
  transform: translateY(-1px);
`

export const HeroLinkSpanArrowStyled = styled('svg')`
  outline: none;
  text-decoration: none;
  fill: none;
  width: ${getStyleSizes(16)};
  height: ${getStyleSizes(16)};
`


export const BannerContainer = styled('div')`
  background-color: ${color_black};
`
export const GradientStyled = styled('div')`
  background-image: url('https://res.cloudinary.com/spiralyze/image/upload/f_auto/assembly/12001/blurred_blobs_1440.webp');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 400px;
  height: 400px;
  position: absolute;
  pointer-events: none;
  right: 50%;
  top: 50%;
  transform: translate(50%, -50%);
  ${mq_min('md')} {
    width: 800px;
    height: 800px;
    right: -30px;
    top: -88px;
    transform: none;
  }
  ${mq_min('lg')} {
    right: -75px;
    top: -70px;
  }
`

export const BannerInner = styled('div')`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  min-height: ${getStyleSizes(679)};
  max-width: 1280px;
  margin: 0 auto;
  ${mq_min('md')} {
    padding-bottom: ${getStyleSizes(56)};
  }
  ${mq_min('lg')} {
    padding-bottom: ${getStyleSizes(72)};
  }
`

export const BannerHeadlineContainer = styled('div')`
  color: ${color_white};
  font-size: ${getStyleSizes(40)};
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  margin:  ${getStyleSizes([0, 0, 0])};
  ${mq_min('md')} {
    font-size: ${getStyleSizes(48)};
  }
  ${mq_min('lg')} {
    margin:  ${getStyleSizes([15  , 0, 26])};
  }

  p > span:nth-of-type(2){
    font-weight: 700;
  }
`

export const BannerContentContainer = styled('div')`
  color: ${color_white};
  font-size: ${getStyleSizes(18)};
  font-weight: 400;
  line-height: 150%;
  margin-bottom: ${getStyleSizes(37)};
  margin-top: ${getStyleSizes(23)};
  max-width: ${getStyleSizes(675)};
  color: ${color_light_grey};
  ${mq_min('md')} {
    margin-bottom: ${getStyleSizes(41)};
  }
  ul,
  ol {
    padding-left: ${getStyleSizes(4)};
    li {
      display: flex;
      flex-basis: 100%;
      width: 100%;
      align-items: flex-start;
      justify-content: flex-start;
      margin-bottom: ${getStyleSizes(16)};
      ${mq_min('md')} {
        width: calc(100% + ${getStyleSizes(15)});
      }
      &:before {
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='12' viewBox='0 0 8 12' fill='none'%3E%3Cpath d='M7.352 4.68953C7.2988 4.65 7.24307 4.61429 7.18533 4.58261L0.985552 0.614912L0.962739 0.598325L0.922379 0.574365C0.807993 0.512132 0.678219 0.488105 0.550466 0.505518C0.422712 0.522925 0.303079 0.580938 0.207606 0.671772C0.112132 0.762612 0.0453923 0.881931 0.016319 1.01373C-0.0127477 1.14554 -0.00274758 1.28352 0.0449724 1.40918L1.40145 5.9998L0.0449724 10.5885C-0.00420092 10.7181 -0.0133077 10.8607 0.0189257 10.9961C0.0511523 11.1315 0.123099 11.2528 0.224579 11.3429C0.326059 11.4329 0.451939 11.4872 0.584406 11.498C0.716872 11.5088 0.849226 11.4756 0.962739 11.4031L1.20139 11.2575L2.0595 10.7047L4.03367 9.44593L7.19233 7.4188C7.2466 7.38726 7.29933 7.3528 7.35027 7.3156C7.5508 7.16913 7.71467 6.97386 7.8278 6.74653C7.94093 6.5192 8 6.26647 8 6.00993C8 5.7534 7.94093 5.50066 7.8278 5.27333C7.71467 5.04593 7.5508 4.85073 7.35027 4.70427L7.352 4.68953Z' fill='%2325C9EF'/%3E%3C/svg%3E");
        margin-right: ${getStyleSizes(13)};
        margin-top: 0;
      }
      span{
        &:first-of-type{
          font-weight: 600;
        }
        &:nth-of-type(1){
          color: ${color_miami.base};
        }
      }
    }
  }
`
export const BannerLogoImageStyled = styled('img')`
  width: ${getStyleSizes(147)};
  height: ${getStyleSizes(40)};
`

export const BannerLeftSideContainer = styled('div')`
  width: 100%;
  align-self: center;
  max-width: 100%;
  margin: 0;
  padding: ${getStyleSizes([20, 16, 0])};
  ${mq_min('md')} {
    padding: ${getStyleSizes([42, 32, 0])};
  }
  ${mq_min('lg')} {
    width: 45%;
    padding: ${getStyleSizes([32, 20, 0])};
  }

  button{
    height: ${getStyleSizes(56)};
    width: 100%;
    ${mq_min('md')} {
      width: ${getStyleSizes(216)};
    }
    span:not(.MuiTouchRipple-root){
      font-size: ${getStyleSizes(18)};
    }
  }
`
export const BannerRightSideContainer = styled('div')`
  width: 100%;
  padding: ${getStyleSizes([42, 16, 19, 6])};
  position: relative;
  ${mq_min('md')} {
  padding: ${getStyleSizes([37, 67, 9, 56])};
  }
  ${mq_min('lg')} {
    padding: ${getStyleSizes([80, 21, 0, 42])};
    width: 55%;
    margin-bottom: 0;
  }
`

export const BannerImageStyled = styled('img')`
  width: 100%;
  z-index: 10;
  position: relative;
`
export const ImageBannerSliderStyled = styled(Splide)`
  position: relative;
  .splide__arrow--prev,
  .splide__arrow--next{
    display: none;
  }
  .splide__slide{
    width: 100%;
    text-align: center;
    ${mq_min('md')} {
      width: 33.3333%;
    }
  }
  .splide__slide:nth-of-type(1),
  .splide__slide:nth-of-type(3){
    img{
      height: 100%;
      max-height:  ${getStyleSizes(98)};
      ${mq_min('lg')} {
        max-height:  ${getStyleSizes(126)};
      }
      width: auto;
    }
  }
  .splide__slide:nth-of-type(2){
    img{
      height: 100%;
      max-height:  ${getStyleSizes(142)};
      ${mq_min('lg')} {
        max-height:  ${getStyleSizes(150)};
      }
      width: auto;
    }
  }
  .splide__pagination{
    display: none;
  }
`;

export const SliderContainer = styled('div')`
  width: 100%;
  padding-bottom:  ${getStyleSizes(40)};
`

export const LogoGridStyled = styled('div')`
  display: flex;
  gap:  ${getStyleSizes(53)};
  padding-left:  ${getStyleSizes(32)};
  padding-right:  ${getStyleSizes(32)};
  width: 100%;
  ${mq_min('lg')} {
    justify-content: space-between;
    margin-top: ${getStyleSizes(34)};
    padding-left:  ${getStyleSizes(20)};
    padding-right:  ${getStyleSizes(20)};
  }
  > div:nth-of-type(1),
  > div:nth-of-type(3){
    img{
      height: 100%;
      max-height:  ${getStyleSizes(98)};
      ${mq_min('lg')} {
        max-height:  ${getStyleSizes(126)};
      }
      width: auto;
    }
  }
 > div:nth-of-type(2){
    img{
      height: 100%;
      max-height:  ${getStyleSizes(142)};
      ${mq_min('lg')} {
        max-height:  ${getStyleSizes(150)};
      }
      width: auto;
    }
  }
`

export const LogoGridItemStyled = styled('div')`
  width: 33.3333%;
   ${mq_min('lg')} {
    width: auto;
  }
`
